import React from "react"
import { useTranslation } from 'react-i18next'

import POIData from "../POIData"

function POICard({idx, isHovered}) {
   const { t } = useTranslation();

   const data = POIData[idx-1]

   return (
      <div className="poicard-container ">
         <div className="poicard-image-containerW">
            <img src={data.img} alt="" />
         </div>
         <h3 style={{color: POIData[idx-1].color}}>{t(data.text)}</h3>
      </div>
   )
}

export default POICard