import React from 'react';

function LocationCardItem (props) {
   return (
      <>
         <div className="locationcarditem">
            <div className="loc-card-text-wrapper">
               {props.htext}
               {props.ptext}
            </div>

            <img className={props.reversed ? 'reversed' : ''} src={props.src} alt="" />
         </div>
      </>
   )
}

export default LocationCardItem