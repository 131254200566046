import React, {useEffect} from 'react'
import '../../App.css'
import p1 from '../../images/exterior/frontal.jpg';
import heropicture from '../../images/exterior/topdown.JPG'
import HeroPicture from '../HeroPicture';
import SimpleCard from '../SimpleCard';
import { useTranslation } from 'react-i18next'
import {motion} from "framer-motion"
// import p2 from '../../images/exterior/tudor3.webp'
// import p3 from '../../images/exterior/tudor1.webp'
// import p4 from '../../images/exterior/tudor2.webp'
// import p5 from '../../images/exterior/tudor4.webp'

export default function Exterior () {
   const { t } = useTranslation();

   const header = <h2>
                     {t("exterior-title")}
                  </h2>

   const pgraph = <p>
                     {t("exterior-text")}
                  </p>
                  
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <motion.div 
         exit={{opacity:0}}
         animate={{opacity:1}}
         initial={{opacity:0}}
         transition={{duration:1}}>
         <HeroPicture src={heropicture} fit={true}></HeroPicture>
         <SimpleCard header={header} pgraph={pgraph} src={p1}></SimpleCard>
      </motion.div>
   )
}