import React, {useEffect} from 'react'
import '../../App.css'
import Gallery from '../Gallery'
import { useTranslation } from 'react-i18next'
import {motion} from "framer-motion"
import p1 from '../../images/interior/openconcept2.webp';
import p2 from '../../images/interior/kitchen2.webp'
import p3 from '../../images/interior/dining.webp'
import p4 from '../../images/interior/kitchen.webp'
import p5 from '../../images/interior/bedroom.webp'
import p6 from '../../images/interior/openconcept.webp'

export default function Interior () {
   const { t } = useTranslation();

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <motion.div
         exit={{opacity:0}}
         animate={{opacity:1}}
         initial={{opacity:0}}
         transition={{duration:1}}>
         <div className='gallery-text-wrapper'>
            <div className="gallery-header">
               <h2>
                  {t("interior-title")}
               </h2>
            </div>
            <p>
            {t("interior-text")}
            </p>
         </div>

         <Gallery
                  src1={p1}
                  src2={p2}
                  src3={p3}
                  src4={p4}
                  src5={p5}
                  src6={p6}/>
      </motion.div>
   )
}