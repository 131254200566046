import React from 'react'
import './TeamTextPanel.css'
import { useTranslation } from 'react-i18next'

function TeamTextPanel() {
   const { t } = useTranslation();

   return (
      <div className='teamtextpanel-container'>
         <p>
            {t('team-blurb')}
         </p>
      </div>
   )
}

export default TeamTextPanel