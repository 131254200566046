import fp1 from './images/floorplans/floorplan_cropped.png'
import fp2 from './images/floorplans/floorplan2_cropped.png'
import fp3 from './images/floorplans/floorplan3_cropped.png'
import fp4 from './images/floorplans/floorplan4_cropped.png'
import fp5 from './images/floorplans/floorplan5_cropped.png'

const unitData = [
   {
      id: 1,
      img: fp1,
      totalarea: 1295,
      rooms: [
         {
            name: 'Main Floor',
            sqft: 637
         },
         {
            name: 'Upper Floor',
            sqft: 658
         }
      ]
   },
   {
      id: 2,
      img: fp2,
      totalarea: 1243,
      rooms: [
         {
            name: 'Main Floor',
            sqft: 622
         },
         {
            name: 'Upper Floor',
            sqft: 621
         }
      ]
   },
   {
      id: 3,
      img: fp3,
      totalarea: 1607,
      rooms: [
         {
            name: 'Main Floor',
            sqft: 858
         },
         {
            name: 'Upper Floor',
            sqft: 749
         }
      ]
   },
   {
      id: 4,
      img: fp4,
      totalarea: 1251,
      rooms: []
   },
   {
      id: 5,
      img: fp5,
      totalarea: 1704,
      rooms: [
         {
            name: 'Main Floor',
            sqft: 700
         },
         {
            name: 'Upper Floor',
            sqft: 1004
         }
      ]
   }
]

export default unitData