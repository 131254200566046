import React, {useEffect} from 'react'
import '../../App.css'
import {motion} from "framer-motion"
import TeamPage from '../TeamPage.js'

export default function Team () {

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <motion.div 
         exit={{opacity:0}}
         animate={{opacity:1}}
         initial={{opacity:0}}
         transition={{duration:1}}>
         <TeamPage></TeamPage>
      </motion.div>
   )
}