import React, {useEffect} from 'react'
import {motion} from "framer-motion"
import '../../App.css'
import HeroPicture from '../HeroPicture'
import Floorplan from '../Floorplan'
import p from '../../images/interior/openconcept3.webp'

export default function Floorplans () {

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <motion.div 
         exit={{opacity:0}}
         animate={{opacity:1}}
         initial={{opacity:0}}
         transition={{duration:1}}>
         <HeroPicture
            src={p}/>
         <Floorplan/>
      </motion.div>
      )
}