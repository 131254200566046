import React, {useState, useEffect} from 'react'
import { Link, NavLink } from 'react-router-dom'
import './Navbar.css';
import { Button } from './Button';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next'
import logo_image from '../images/karnaby_logo.png'

function Navbar() {
   const { t } = useTranslation();

   const [click, setClick] = useState(false);
   const [button, setButton] = useState(true);

   const handleClick = () => setClick(!click);
   const closeMobileMenu = () => setClick(false);

   const showButton = () => {
      if (window.innerWidth <= 1290) {
         setButton(false);
      } else {
         setButton(true);
      }
   };

   useEffect(() => {
      showButton()
   }, [])

   window.addEventListener('resize', showButton);

   return (
      <>
         <nav className="navbar"> 
            <div className="navbar-container">
               <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                  <img src={logo_image} alt='karnaby logo' id='karnaby-logo-img'/>
               </Link>
               <div className='menu-icon' onClick={handleClick}>
                  <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
               </div>
               <div className='nav-menu-container'>
                  <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                     <li className='nav-item'>
                        <NavLink to='/' className='nav-links' activeClassName='active' onClick={closeMobileMenu}>
                           {t("OVERVIEW")}
                        </NavLink>
                     </li>
                     <li className='nav-item'>
                        <NavLink to='/exterior' className='nav-links' activeClassName='active' onClick={closeMobileMenu}>
                           {t("EXTERIOR")}
                        </NavLink>
                     </li>
                     <li className='nav-item'>
                        <NavLink to='/interior' className='nav-links' activeClassName='active' onClick={closeMobileMenu}>
                           {t("INTERIOR")}
                        </NavLink>
                     </li>
                     <li className='nav-item'>
                        <NavLink to='/floorplans' className='nav-links' activeClassName='active' onClick={closeMobileMenu}>
                           {t("FLOORPLANS")}
                        </NavLink>
                     </li>
                     <li className='nav-item'>
                        <NavLink to='/location' className='nav-links' activeClassName='active' onClick={closeMobileMenu}>
                           {t("LOCATION")}
                        </NavLink>
                     </li>
                     <li className='nav-item'>
                        <NavLink to='/team' className='nav-links' activeClassName='active' onClick={closeMobileMenu}>
                           {t("TEAM")}
                        </NavLink>
                     </li>
                     <li className='nav-item'>
                        <LanguageSelector/>
                     </li>
                     <li className='nav-item'>
                        <Link to='/register' className='nav-links-mobile' onClick={closeMobileMenu}>
                           {t("REGISTER")}
                        </Link>
                     </li>
                  </ul>
                  {button && <Button buttonStyle='btn--outline'>{t("REGISTER")}</Button>}
               </div>
            </div>
         </nav>
      </>
   )
}

export default Navbar