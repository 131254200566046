import './HeroSection.css';
import '../App.css';

function HeroPicture(props) {
  return (
    <div className='hero-container'>
      <img src={props.src} className={props.fit ? "contain" : "cover"} alt="" />
      {/* <h1>Townhouses</h1>
      <p>Luxury and elegance.</p> */}
    </div>
  )
}

export default HeroPicture