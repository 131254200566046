import React from 'react';
import './Gallery.css';


function Gallery (props) {
   return (
      <>
         <div className='gallery-container'>
            <div className="gallery-pics-wrapper">
               <div className='image-container'>
                  <img src={props.src1} alt="" />
               </div>
               <div className='image-container'>
                  <img src={props.src2} alt="" />
               </div>
               
               <div className="pics-container">
                  <div className='image-container in-pic-container'>
                     <img src={props.src3} alt="" />
                  </div>
                  
                  <div className='image-container in-pic-container'>
                     <img src={props.src4} alt="" />
                  </div>
                  
               </div>
               <div className='image-container'>
                  <img src={props.src5} alt="" />
               </div>
               
               <div className='image-container'>
                  <img src={props.src6} alt="" />
               </div>
               
            </div>
         </div>
      </>
   )
}

export default Gallery