import React from 'react'
import { useTranslation } from 'react-i18next'

function FloorAreaPanel(props) {
   const { t } = useTranslation();

   const currUnit = props.unitData[props.unit - 1]

   const roomArray = currUnit.rooms;

   const rooms = roomArray.map((room) => {
      return <li className="floorarea-item" key={room.name}><span>{t(room.name)}</span> <span className='sqft-span'>{room.sqft} sqft</span></li>
   })

   return (
      <div className="floorareapanel">
         <ul className="floorarea-list">
            <li className="floorarea-item-totalarea"><span>{t("Total Area")}</span> <span className='sqft-span'>{currUnit.totalarea} sqft</span></li>
            {rooms}
         </ul>
      </div>
   )
}

export default FloorAreaPanel