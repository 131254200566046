import React, {useRef, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import './RegistrationForm.css';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha"
import ReactDOM from 'react-dom';

function RegistrationForm () {
   const { t } = useTranslation();

   const captchaRef = useRef(null)

   // Makes sure captcha is rendered to DOM
   useEffect(()=>{
      const parentDiv = document.getElementById("captcha-wrapper")
      ReactDOM.render(
         <ReCAPTCHA
         sitekey="6LfYXVQmAAAAAGu9AI8mOsiAgBH4Ui-L9IP38enN"
         ref={captchaRef}
         onChange={toggleCaptchaSolved}/>,
      parentDiv,
      );
      }, []);

   const initFormData = {
      firstName: "",
      lastName: "",
      emailAddress: "",
      phoneNumber: "",
      realtor: "",
      brokerage: "",
      contactMethodCall: false,
      contactMethodText: false,
      contactMethodEmail: false,
      comments: ""
   }

   const [formData, setFormData] = React.useState(initFormData)

   const [isCaptchaSolved, setIsCaptchaSolved] = React.useState(false)

   const [showCaptchaError, setShowCaptchaError] = React.useState(false)

   const [isSubmitSuccess, setIsSubmitSuccess] = React.useState(false)

   const [isAPIError, setIsAPIError] = React.useState(false)


   function handleChange(event) {
      const {name, value, type, checked} = event.target
      setFormData(prevFormData => {
         return {
            ...prevFormData,
            [name]: type === "checkbox" ? checked : value
         }
      })
   }

   function handleSubmit(event) {
      event.preventDefault()

      // Reset messages
      setIsAPIError(false)
      setIsSubmitSuccess(false)
      setShowCaptchaError(false)

      // Immediately return if captcha not solved, show error
      
      if (!isCaptchaSolved) {
         setShowCaptchaError(true)
         return
      }

      const token = captchaRef.current.getValue();
      const params = {
         ...formData,
         'g-recaptcha-response': token,
       };
       
      emailjs.send('service_b4c8ndt', 'template_2verqqg', params, 'KmXjeapNUuoY9tcxS')
               .then((result) => {
                  console.log(result.text);
                  setIsSubmitSuccess(true)
                  var regButton = document.getElementById('register-button')
                  regButton.style.backgroundColor='green'
                  regButton.style.cursor='auto'
                  regButton.textContent=t("SUCCESS!")
                  regButton.disabled=true

               }, (error) => {
                  console.log(error.text);
                  setIsAPIError(true)
               });

      // Reset captcha
      captchaRef.current.reset();
      toggleCaptchaSolved();
   }

   function toggleCaptchaSolved() {
      setIsCaptchaSolved((prevIsCaptchaSolved => !prevIsCaptchaSolved))
   }

   return (
      <>
         <div className="registrationform">
            <div className="header-wrapper">
               <h1>
                  {t("register-title")}
               </h1>
            </div>
            
            <div className="form-wrapper">
               <form id="register-form" onSubmit={handleSubmit}>
                  <div className="input-fields-container">
                  <div>
                     <label htmlFor="firstName">{t("First-Name")}</label>
                     <input 
                        type="text" 
                        name="firstName" 
                        onChange={handleChange} 
                        value={formData.firstName} 
                        required
                     />
                  </div>

                  <div>
                     <label htmlFor="lastName">{t("Last-Name")}</label>
                     <input 
                        type="text" 
                        name="lastName"
                        onChange={handleChange} 
                        value={formData.lastName}
                        required
                     />
                  </div>

                  <div>
                     <label htmlFor="emailAddress">{t("Email")}</label>
                     <input 
                        type="email" 
                        name="emailAddress"
                        onChange={handleChange}
                        value={formData.emailAddress}
                        required/>
                  </div>

                  <div>
                     <label htmlFor="phoneNumber">{t("Phone")}</label>
                     <input 
                        type="tel" 
                        name="phoneNumber"
                        onChange={handleChange}
                        value={formData.phoneNumber}
                        required/>
                  </div>

                  <div>
                     <label id='realtor-label' htmlFor="realtor">{t("realtor-text")}</label>
                        <input 
                           type="text" 
                           name="realtor" 
                           onChange={handleChange} 
                           value={formData.realtor}
                           required/>
                  </div>

                  <div>
                     <label htmlFor="brokerage">{t("Brokerage")}</label>
                     <input 
                        type="text" 
                        name="brokerage"
                        value={formData.brokerage}
                        onChange={handleChange}/>
                  </div>
                  </div>
                  

                  <div className='contact-method-container'>
                     <label htmlFor="register-contactmethod">{t("contactmtd-text")}</label>
                     <div className="checkbox-container">
                        <label htmlFor="method-call">
                           <input 
                              type="checkbox" 
                              name="contactMethodCall" 
                              id="method-call" 
                              value={formData.contactMethodCall} 
                              onChange={handleChange}
                           /> {t("Call")}
                        </label>
                        <label htmlFor="method-text">
                           <input 
                              type="checkbox" 
                              name="contactMethodText" 
                              id="method-text" 
                              value={formData.contactMethodText} 
                              onChange={handleChange}
                           /> {t("Text")}
                        </label>
                        <label htmlFor="method-email">
                           <input 
                              type="checkbox" 
                              name="contactMethodEmail" 
                              id="method-email" 
                              value={formData.contactMethodEmail} 
                              onChange={handleChange}
                           /> {t("Email-noast")}
                        </label> 
                     </div>
                  </div>

                  <div className='comments-container'>
                     <label htmlFor="comments">{t("Comments")}</label>
                     <textarea 
                        name="comments" 
                        id="comments" 
                        cols="30" 
                        rows="10"  
                        value={formData.comments}
                        onChange={handleChange}/>
                  </div>

                  <label id='privacy-policy-label' htmlFor="register-privacy">
                     <input type="checkbox" name="register-privacy" id="register-privacy" value="agree" required/> {t("privacy-text-pt1")} <Link to='/privacy'>{t("privacy-text-pt2")}</Link>.
                  </label>
                  
               </form>

               <div id='captcha-wrapper'>

               </div>
               
               {showCaptchaError && <p style={{color:'red', fontSize: '20px'}}>{t("warning-solvecaptcha")}</p>}
               {isSubmitSuccess && <p style={{color:'green', fontSize: '20px'}}>{t("msg-registersuccess")}</p>}
               {isAPIError && <p style={{color:'red', fontSize: '20px'}}>{t("warning-unexperror")}</p>}
               <div className='button-wrapper'>
                  <button className="btn btn--primary btn--medium" form="register-form" id="register-button">{t("REGISTER")}</button>
               </div>

            </div>
         </div>
      </>
   )
}

export default RegistrationForm;