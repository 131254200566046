import React from 'react'
import './TeamPage.css'
import TeamTextPanel from './TeamTextPanel'
import PartnerPanel from './PartnerPanel'
import { useTranslation } from 'react-i18next'

function TeamPage() {
   const { t } = useTranslation();

   return (
      <div className='teampage-container'>
         <h1>{t("the-team")}</h1> 
         <div className='team-content-container'>
            <PartnerPanel></PartnerPanel>
            <TeamTextPanel></TeamTextPanel>
         </div>
      </div>
   )
}

export default TeamPage