import img1 from './images/home/schools.jpg'
import img2 from './images/home/skytrain.jpg'
import img3 from './images/home/frontal.jpg'
import img4 from './images/home/vandusen.webp'
import img5 from './images/home/qepark.jpg'
import img6 from './images/home/oakridge.jpg'



const POIData = 
   [
      {
         id: 1,
         text: "poi-lfayh",
         img: img1,
         color: '#EF54DB',
         poitype: 'Education'
      },
      {
         id: 2,
         text: "poi-cline",
         img: img2,
         color: '#8F8F8F',
         poitype: 'Transit'
      },
      {
         id: 3,
         text: "poi-karnaby",
         img: img3,
         color: '#2D8DC2',
         poitype: 'Development'
      },
      {
         id: 4,
         text: "poi-vandusen",
         img: img4,
         color: '#3FB256',
         poitype: 'Outdoor'
      },
      {
         id: 5,
         text: "poi-qepark",
         img: img5,
         color: '#3FB256',
         poitype: 'Outdoor'
      },
      {
         id: 6,
         text: "poi-oakridge",
         img: img6,
         color: '#F29121',
         poitype: 'Shopping'
      },
   ]

export default POIData