import React from 'react'
import { Button } from './Button'
import './Footer.css'
import { Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import karnaby_logo from '../images/karnaby_logo_notext.png'

function Footer() {
   const { t } = useTranslation();

  return (
    <div className='footer-container' id='footer-container'>
      <div className='footer-logo'>
         <img src={karnaby_logo} alt='karnaby logo' id='footer-logo-img'/>
      </div>
      <div className="footer-info">
         <h3>{t("INFO")}</h3>
         <p>
            (604) 218 - 8972 <br/>
            info@karnabyone.com
         </p>
      </div>

      <div className="footer-address">
         <h3>{t("ADDRESS")}</h3>
         <p>
            1177 West 33rd Ave. <br/>
            Vancouver, B.C. <br/>
         </p>
      </div>
      <div className='footer-link-wrapper'>
         <ul className='footer-links'>
         <li className='footer-nav-item'>
            <Link to='/' className='footer-nav-links'>
               {t("OVERVIEW")}
            </Link>
         </li>
         <li className='footer-nav-item'>
            <Link to='/exterior' className='footer-nav-links'>
               {t("EXTERIOR")}
            </Link>
         </li>
         <li className='footer-nav-item'>
            <Link to='/interior' className='footer-nav-links'>
               {t("INTERIOR")}
            </Link>
         </li>
         <li className='footer-nav-item'>
            <Link to='/floorplans' className='footer-nav-links'>
               {t("FLOORPLANS")}
            </Link>
         </li>
         <li className='footer-nav-item'>
            <Link to='/location' className='footer-nav-links'>
               {t("LOCATION")}
            </Link>
         </li>
         <li className='footer-nav-item'>
            <Link to='/team' className='footer-nav-links'>
               {t("TEAM")}
            </Link>
         </li>
         </ul>
      </div>
      <div id='footer-reg-button'>
         <Button buttonStyle='btn--outline'>{t("REGISTER")}</Button>
      </div>
    </div>
  )
}

export default Footer