import React, {useState}  from 'react';
import '../App.css'
import './HeroSection.css';


function HeroSection(props) {
  const [click, setClick] = useState(false);
  const handleClick = () => {setClick(!click); props.toggleMuted("heroVideo");}

  return (
    <div className='hero-container'>
      <video id='karnaby-video' src={props.video} autoPlay loop muted playsInline/>  
      <div className='mute-icon' onClick={handleClick}>
        <i id='ovvid-mute-icon' className={props.muted.heroVideo ?  'fas fa-volume-high' : 'fas fa-volume-xmark' }/>
      </div>
    </div>
  )
}

export default HeroSection