import React from 'react';
import './LocationCards.css';
import LocationCardItem from './LocationCardItem';
import { useTranslation } from 'react-i18next'
import p1 from '../images/location/kerrisdale.jpg'
import p2 from '../images/location/qepark.jpg'
import p3 from '../images/location/education.jpg'

function LocationCards () {
   const { t } = useTranslation();

   return (
      <>
         <div className="header-container">
            <h1>{t("location-title")}</h1>
         </div>
         <div className="locationcards">
            <LocationCardItem
               htext={<h2>{t("OUTDOORS")}</h2>}
               ptext={<p>{t("outdoors-text")}</p>}
               src={p2}/>
            <LocationCardItem 
               reversed='true'
               htext={<h2>{t("EDUCATION")}</h2>}
               ptext={<p>{t("education-text")}</p>}
               src={p3}/>
            <LocationCardItem
               htext={<h2>{t("SHOPPING")}</h2>}
               ptext={<p>{t("shopping-text")}</p>}
               src={p1}/>         
         </div>
      </>
   )
}

export default LocationCards
