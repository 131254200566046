import React, {useEffect} from 'react';
import {motion} from 'framer-motion'
import '../../App.css';

function Privacy() {

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <motion.div 
         exit={{opacity:0}}
         animate={{opacity:1}}
         initial={{opacity:0}}
         transition={{duration:1}}>
         <div className='privacy-container'>
            <h1> PRIVACY POLICY</h1>
            <div className="policy-wrapper">
               <p>This policy statement sets out the privacy policies and practices for the Karnaby One townhouse development website with respect to the collection and use of personal information that we gather from individuals accessing the website.</p>
               <p>1. The website collects personal information from customers and potential customers through the means of guest registration sign-up sheets. The type of information collected will vary depending on the means through which an individual submits his/her personal information. The type of information collected may include an individual’s name, residential address, e-mail address, home phone number, preferred marketing mediums and specific comments. We do not disclose Personal Information to third parties unless required by applicable law. We do not regard Personal Information that has been anonymized or aggregated to prevent the identification of a specific individual to continue to be Personal Information. The Karnaby One townhouse development uses personal information collected from customers and potential customers to provide products or services, to respond to requests for information regarding our products and services, for input on preferred features for future developments, mailing contacts for Karnaby One publications and newsletters, special events marketing and other marketing research activities, and for additional purposes that may be identified at or before the time that the information is collected. On giving reasonable written notice through the email address info@karnabyone.com, an individual may withdraw consent to the collection, use or disclosure of his or her personal information. </p>
               <p>2. Except where required or permitted by law, the website will limit the collection of personal information to that which is necessary to fulfill the purposes for which it is collected. </p>
               <p>3. By “opting-in” the individual has given express consent to Karnaby One townhouse development for sending mail, emails or text messages through the information provided, promotional messages such as newsletters, announcements, and event invitations regarding their current and future products and services. </p>

            </div>
         </div>
      </motion.div>  
   )
}

export default Privacy