import React from 'react'
import './PartnerPanel.css'
import PartnerItem from './PartnerItem'
import img1 from '../images/team/sungrowth.png'
import img2 from '../images/team/lq.webp'
import img3 from '../images/team/mrpdesign.webp'
import img4 from '../images/team/totalreliance.webp'
import img5 from '../images/team/alphastone.webp'
import img6 from '../images/team/mylch.webp'
import img7 from '../images/team/belega.webp'
import img8 from '../images/team/royalpacific.webp'

function PartnerPanel() {

   return (
      <div className='partnerpanel-container'>
         <PartnerItem
            description="teamitem-development"
            phone="604-278-9597"
            email="sungrowth99@gmail.com"
            src={img1}
            pdf="Sungrowth.pdf"/>
         <PartnerItem
            description="teamitem-architect"
            phone="604-773-5135"
            email="lq.design05@gmail.com"
            src={img2}
            pdf="lqdesign.pdf"/>
         <PartnerItem
            description="teamitem-interior"
            phone="604-370-3578"
            email="mrpdesign88@gmail.com"
            src={img3}
            pdf="mrpinteriors.pdf"/>
         <PartnerItem
            description="teamitem-construction"
            phone="236-999-1798"
            email="trdccanada@gmail.com"
            src={img4}
            pdf="totalreliance.pdf"/>
         <PartnerItem
            description="teamitem-stone"
            phone="604-278-8267"
            email="info@alphastone.ca"
            src={img5}
            link="https://alphastone.ca/"
            smalllogo={true}/>
         <PartnerItem
            description="teamitem-windows"
            phone="778-881-2946"
            email="info@mylch.com"
            src={img6}
            link="https://mylchwindows.com/"
            smalllogo={true}/>
         <PartnerItem
            description="teamitem-kitchen"
            phone="604-723-1722"
            email="info@belegainternational.com"
            src={img7}
            link="https://belegainternational.com/"
            smalltext={true}/>
         <PartnerItem
            description="teamitem-marketing"
            phone="604-218-8972"
            email="jeanzhangprec@gmail.com"
            src={img8}
            pdf="royalpacific.pdf"/>
      </div>
   )
}

export default PartnerPanel