import React, {useState} from "react";
import i18n from '../i18n';

function LanguageSelector() {
    
   const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

   const switchLanguage = (e) => {
      e.preventDefault();
      if (selectedLanguage === 'en') {
         i18n.changeLanguage('zh');   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
         setSelectedLanguage('zh')
      } else {
         i18n.changeLanguage('en');
         setSelectedLanguage('en')
      }
    }

      return (
            <li className="lang-selector nav-links" style={{cursor:'pointer'}} onClick={switchLanguage}>{selectedLanguage==='zh' ? 'ENG' : '中文'}</li>
      );
   };

export default LanguageSelector;