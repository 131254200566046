import React from 'react'
import POICard from './POICard'
import POI from './POI'
import { CSSTransitionGroup } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import './POIMap.css'
import mapimg from '../images/home/map.webp'


function POIMap() {
   const { t } = useTranslation();
   
   const [POIIndex, setPOIIndex] = React.useState(1)

   const [isHovered, setIsHovered] = React.useState(false)

   function toggleIsHovered() {
      setIsHovered((prevIsHovered) => !prevIsHovered)
   }

   return (
      <div className="poimap-container">
         <div className="poimap-cardarea">
            <h1>{t("poimap-title")}</h1>
            <CSSTransitionGroup
               transitionName="poicard-transition"
               transitionEnterTimeout={500}
               transitionLeaveTimeout={200}>
               {isHovered && <POICard idx={POIIndex} isHovered={isHovered}/>}
            </CSSTransitionGroup>
            
         </div>
         <div className="poimap-map-container">
            <svg className='svg-map' preserveAspectRatio="none" viewBox='0 0 1413 1158'>
            <defs>
               <clipPath id="rectView">
                     <rect rx='30' ry='30' width='1413' height='1158'/>
               </clipPath>
            </defs>
            
            
            <image href={mapimg} clipPath="url(#rectView)"></image>
            <POI
               x={350}
               y={350}
               idx={1}
               setPOIIndex={setPOIIndex}
               toggleIsHovered={toggleIsHovered}
            />
            <POI
               x={1100}
               y={275}
               idx={2}
               setPOIIndex={setPOIIndex}
               toggleIsHovered={toggleIsHovered}
            />
            <POI
               x={700}
               y={600}
               idx={3}
               setPOIIndex={setPOIIndex}
               toggleIsHovered={toggleIsHovered}
            />
            <POI
               x={625}
               y={740}
               idx={4}
               setPOIIndex={setPOIIndex}
               toggleIsHovered={toggleIsHovered}
            />
            <POI
               x={1225}
               y={640}
               idx={5}
               setPOIIndex={setPOIIndex}
               toggleIsHovered={toggleIsHovered}
            />
            <POI
               x={1075}
               y={1050}
               idx={6}
               setPOIIndex={setPOIIndex}
               toggleIsHovered={toggleIsHovered}
            />

            </svg>
         </div>
      </div>
   )
}

export default POIMap