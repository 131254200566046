import React, {useEffect} from 'react';
import '../../App.css';
import {motion} from "framer-motion"
import LocationCards from '../LocationCards';
import HeroPicture from '../HeroPicture';
import p from '../../images/location/garden.webp'

export default function Location () {

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);
   
   return (
      <motion.div 
         exit={{opacity:0}}
         animate={{opacity:1}}
         initial={{opacity:0}}
         transition={{duration:1}}>
         <HeroPicture
            src={p}/>
         <LocationCards/>
      </motion.div>
   )
}