import './SimpleCard.css';
import '../App.css';

function SimpleCard(props) {
   return (
      <>
         <div className='simplecard-container'>
            <div className='simplecard-text-wrapper'>
                  <div className="simplecard-header">
                     {props.header}
                  </div>
                  {props.pgraph}
            </div>
            <div className='simplecard-img-container'>
               <img src={props.src} alt=""></img>
            </div>
         </div>
      </>
   )
}

export default SimpleCard