import React, {useEffect} from 'react';
import { AnimatePresence } from 'framer-motion';
import './App.css';
import {Routes, Route, useLocation} from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/pages/Home'
import Floorplans from './components/pages/Floorplans';
import Exterior from './components/pages/Exterior';
import Interior from './components/pages/Interior';
import Location from './components/pages/Location';
import Team from './components/pages/Team';
import Footer from './components/Footer';
import Register from './components/pages/Register';
import Privacy from './components/pages/Privacy';


function App() {
  const location = useLocation();

  function toggleFooterRegButton(location) {

    var regbutton = document.getElementById('footer-reg-button');
    var footer = document.getElementById('footer-container')

    if (location.pathname === '/register') {
       regbutton.style.opacity = 0;
      //  console.log("opacity 0")
    } else {
       regbutton.style.opacity = 1;
      //  console.log("opacity 1")
    }

    if (location.pathname === '/location') {
      footer.style.borderTop = 'none'
    } else {
      footer.style.borderTop = '3px solid rgb(45, 141, 194)'
    }

    // console.log('toggled reg button');
 }

  useEffect(() => {
      // execute on location change
      console.log('Location changed!', location.pathname);
      toggleFooterRegButton(location);
      // window.scrollTo(0, 0);
  }, [location]);
  
  return (
    <>
      
      <Navbar/>
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route exact path='/' element={<Home/>} />
            <Route path='/floorplans' element={<Floorplans/>}/>
            <Route path='/exterior' element={<Exterior/>}/>
            <Route path='/interior' element={<Interior/>}/>
            <Route path='/location' element={<Location/>}/>
            <Route path='/team' element={<Team/>}/>
            <Route path='/register' element={<Register/>}/>
            <Route path='/privacy' element={<Privacy/>}/>
          </Routes>
        </AnimatePresence>
      <Footer/>
    
    </>
  );
}

export default App;
