import React, {useEffect} from 'react';
import '../../App.css';
import RegistrationForm from '../RegistrationForm';
import {motion} from "framer-motion"


function Register () {

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <motion.div 
         exit={{opacity:0}}
         animate={{opacity:1}}
         initial={{opacity:0}}
         transition={{duration:1}}>
         <RegistrationForm/>
      </motion.div>
   )
}

export default Register;