import React from 'react'
import './PartnerItem.css'
import { useTranslation } from 'react-i18next'

function PartnerItem(props) {
   const { t } = useTranslation();

   const logo = <img src={props.src} style={props.smalllogo ? {maxWidth: "240px"} : {}}></img>

   return (
      <div className='partneritem-container'>
         <div className='partneritem-img-container'>
            {props.link ? <a target="_blank" href= {props.link} >{logo}</a> : props.pdf ? <a target="_blank" href= {props.pdf}>{logo}</a> : {logo}}           
         </div>
         
         <h2>{t(props.description)}</h2>
         <div className='hide'>
            <p>{props.phone}</p>
            <p style={props.smalltext ? {fontSize: "1rem"} : {}}>{props.email}</p>
         </div>
      </div>
   )
}

export default PartnerItem