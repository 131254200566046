import React from 'react'
import POIData from '../POIData'
import { useTranslation } from 'react-i18next'

function POI({x, y, idx, setPOIIndex, toggleIsHovered}) {
   const { t } = useTranslation();

   const initRadius = 18
   const hoverRadius = 25

   const [radius, setRadius] = React.useState(initRadius)

   function handleOnMouseEnter() {
      setPOIIndex(idx)
      setRadius(hoverRadius)
      toggleIsHovered()
   }

   function handleOnMouseLeave() {
      setRadius(initRadius)
      toggleIsHovered()
   }

   return (
      <g onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
         <circle cx={x} cy={y} r={30} fill="white"/>
         <circle cx={x} cy={y} r={radius} fill={POIData[idx-1].color}/>
         <text textAnchor='middle' fontSize='35' fontWeight='700' fill='white' x={x} y={y-50}>{t(POIData[idx-1].poitype)}</text>
      </g>
   )
}

export default POI