import React, {useState}  from 'react';
import './VideoPlayer.css'
import { useTranslation } from 'react-i18next'

function VideoPlayer (props) {
   const { t } = useTranslation();
   
   const [click, setClick] = useState(false);
   const handleClick = () => {setClick(!click); props.toggleMuted("videoPlayer");}

   return (
      <>
         <div className="videoplayer">
            <div className='header-wrapper'>
               <h1>{t("ovvid-title")}</h1>
            </div>
            <div className='video-container'>
               <div className='mute-icon' onClick={handleClick}>
                  <i className={props.muted.videoPlayer ?  'fas fa-volume-high' : 'fas fa-volume-xmark'}/>
               </div>
               <video id='karnaby-video-2' src={props.video} autoPlay loop muted/>
            </div>
         </div>
      </>
   )
}

export default VideoPlayer