import React from 'react'
import './Floorplan.css'
import FloorAreaPanel from './FloorAreaPanel'
import unitData from '../unitData.js'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n';

function Floorplan () {
   const { t } = useTranslation();

   const [open, setOpen] = React.useState(false);

   const [unit, setUnit] = React.useState(1);

   function handleMenu(id) {
      setUnit(id);
      setOpen(false);
   }

   return (
      <div className="floorplan">
         <h1>{t("floorplans-title")}</h1>
         <div className="floorarea-container">
            <div className="floorarea-dropdown">
               <div className='floorarea-dropdown-container'>
                  <label>{t("Select")}</label>
                  <ul className="menu">
                     {/* <i className="fa-solid fa-caret-up"></i> */}
                     <li className={"menu-item"}>
                        <button onClick={()=>handleMenu(1)} className={(unit === 1 ? "active" : "")}>{t("Unit 1")}</button>
                     </li>
                     <li className="menu-item">
                        <button onClick={()=>handleMenu(2)} className={(unit === 2 ? "active" : "")}>{t("Unit 2")}</button>
                     </li>
                     <li className="menu-item">
                        <button onClick={()=>handleMenu(3)} className={(unit === 3 ? "active" : "")}>{t("Unit 3")}</button>
                     </li>
                     <li className="menu-item">
                        <button onClick={()=>handleMenu(4)} className={(unit === 4 ? "active" : "")}>{t("Unit 4")}</button>
                     </li>
                     <li className="menu-item">
                        <button onClick={()=>handleMenu(5)} className={(unit === 5 ? "active" : "")}>{t("Unit 5")}</button>
                     </li>
                  </ul>
               </div>
               
               <div>
                  <FloorAreaPanel
                     unitData={unitData}
                     unit={unit}/>
                  <a href={i18n.language === 'en' ? 'allfloorplans.pdf' : 'allfloorplanszh.pdf'} download>
                     <button className='download-button'> {t("Download All")}</button></a>
               </div>
            </div>
            <div className='floorplan-img-container'>
               <img className="floorplan-img" src={unitData[unit - 1].img} alt="" />
            </div>
         </div>
         
         
      </div>
   )
}

export default Floorplan