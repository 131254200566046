import React, {useEffect} from 'react';
import {motion} from "framer-motion"
import '../../App.css';
import HeroSection from '../HeroSection';
// import Fade from 'react-reveal/Fade';
import VideoPlayer from '../VideoPlayer';
import POIMap from '../POIMap';
import video from '../../videos/karnaby_video.mp4'
import herovideo from '../../videos/karnaby_video_2.mp4'

function Home () {
   const initMuted = {
      heroVideo : true,
      videoPlayer : true
   }

   const [muted, setMuted] = React.useState(initMuted)

   function toggleMuted(video) {
      var videoPlayerEle = document.getElementById('karnaby-video-2');
      var heroVideoEle = document.getElementById('karnaby-video');

     if (video === "heroVideo") {
      setMuted(prevMuted => {
         heroVideoEle.muted = !prevMuted.heroVideo
         videoPlayerEle.muted = true

         return {
            heroVideo : !prevMuted.heroVideo,
            videoPlayer : true
         }
      })
     } else {
      setMuted(prevMuted => {
         videoPlayerEle.muted = !prevMuted.videoPlayer
         heroVideoEle.muted = true

         return {
            heroVideo : true,
            videoPlayer : !prevMuted.videoPlayer
         }
      })
     }
   }

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <motion.div 
         exit={{opacity:0}}
         animate={{opacity:1}}
         initial={{opacity:0}}
         transition={{duration:1}}>
         <HeroSection 
            toggleMuted = {toggleMuted}
            muted = {muted}
            video={herovideo}/>
         <POIMap/>
         <VideoPlayer
            toggleMuted = {toggleMuted}
            muted = {muted}
            video={video}/>
      </motion.div>
   );
}

export default Home